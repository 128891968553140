import styled from "styled-components";

export const Wrapper = styled.section`
  overflow: hidden;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  padding: 32px 0;
  background: var(--brand5);
  color: white;
  text-align: center;

  > svg:nth-child(1) {
    position: absolute;
    right: -1%;
    bottom: -1%;
    width: auto;
    height: 20%;
    opacity: 0.5;

    path,
    rect {
      fill: var(--brand2);
    }
  }

  @media (min-width: ${({ theme }) => theme.breakpoints[0]}) {
  }

  @media (min-width: ${({ theme }) => theme.breakpoints[1]}) {
    padding: 48px 0;

    > svg:nth-child(1) {
      height: 30%;
    }
  }

  @media (min-width: ${({ theme }) => theme.breakpoints[2]}) {
    padding: 54px 0;

    > svg:nth-child(1) {
      height: 50%;
    }
  }
`;
