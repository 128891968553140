import React from "react";
import PropTypes from "prop-types";

import { MainContentWrapper } from "./styles.js";

const Main = ({ active, children }) => {
  return <MainContentWrapper active={active}>{children}</MainContentWrapper>;
};

Main.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
};

export default Main;
