import React from "react";
import PropTypes from "prop-types";

import { Wrapper, Image, Content } from "./styles.js";

import HorWrap from "@atoms/HorWrap";
import Text from "@atoms/Text";

const HalfHalf = ({ active, color, image, imageRight, header, text }) => {
  return (
    <HorWrap>
      <Wrapper active={active}>
        <Image color={color} imageRight={imageRight}>
          {image && <img src={image} />}
        </Image>
        <Content>
          <Text>
            <h3>{header}</h3>
            <p>{text}</p>
          </Text>
        </Content>
      </Wrapper>
    </HorWrap>
  );
};

HalfHalf.propTypes = {
  active: PropTypes.bool,
  color: PropTypes.string,
  imageRight: PropTypes.bool,
  header: PropTypes.string,
  text: PropTypes.string,
};

HalfHalf.defaultProps = {
  active: true,
  color: "var(--brand1)",
  imageRight: false,
  header: "Header",
  text: "Text",
};

export default HalfHalf;
