import React from "react";
import PropTypes from "prop-types";

import HorWrap from "@atoms/HorWrap";

import HeroWebp from "@assets/hero.webp";

import { Wrapper, Top, Bottom } from "./styles.js";

const HeroSection = ({ active, header, subheader, children }) => {
  return (
    <Wrapper active={active}>
      <HorWrap>
        <Top>
          <div>
            <img src={HeroWebp} />
          </div>
          <div>
            <h1>{header}</h1>
          </div>
        </Top>
        <Bottom>
          <h2>{subheader}</h2>
          {children && children}
        </Bottom>
      </HorWrap>
    </Wrapper>
  );
};

HeroSection.propTypes = {
  active: PropTypes.bool,
  header: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  subheader: PropTypes.string,
  buttons: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

HeroSection.defaultProps = {
  active: false,
  header: "Header",
  subheader: "Subheader",
  buttons: null,
  children: null,
};

export default HeroSection;
