import styled, { css } from "styled-components";

export const MainContentWrapper = styled.div`
  position: relative;
  z-index: 7000;
  position: relative;
  margin: 0 auto;
  max-width: 2400px;
  ${({ active }) =>
    active
      ? css`
          filter: blur(0);
          opacity: 1;
        `
      : css`
          filter: blur(2px);
          opacity: 0;
        `}
  transition: all 0.6s ease;
`;
