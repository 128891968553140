import styled from "styled-components";

export const Wrapper = styled.section`
  background: var(--white);

  @media (min-width: ${({ theme }) => theme.breakpoints[0]}) {
  }

  @media (min-width: ${({ theme }) => theme.breakpoints[1]}) {
  }

  @media (min-width: ${({ theme }) => theme.breakpoints[2]}) {
  }
`;
