import styled, { css } from "styled-components";

export const Wrapper = styled.section`
  display: flex;
  align-items: stretch;
  justify-content: center;

  @media (min-width: ${({ theme }) => theme.breakpoints[0]}) {
  }

  @media (min-width: ${({ theme }) => theme.breakpoints[1]}) {
  }

  @media (min-width: ${({ theme }) => theme.breakpoints[2]}) {
  }
`;

export const Image = styled.aside`
  position: relative;
  width: 55%;
  padding-top: 64px;
  padding-top: 64px;
  padding-${({ imageRight }) => (imageRight ? "left" : "right")}: 64px;
  background-color: ${({ color }) => color};
  ${({ imageRight }) => imageRight && "order: 2;"}

  &::before {
    content: "";
    position: absolute;
    top: 0;
    ${({ imageRight }) => (imageRight ? "left" : "right")}: 100%;
    width: 50vw;
    height: 100%;
    background-color: inherit;
  }

  > img {
    width: 100%;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints[0]}) {
  }

  @media (min-width: ${({ theme }) => theme.breakpoints[1]}) {
  }

  @media (min-width: ${({ theme }) => theme.breakpoints[2]}) {
  }
`;

export const Content = styled.div`
  position: relative;
  width: 45%;
  padding: 64px;
  font-size: 34px;

  &::before {
    position: absolute;
    top: 0;

    content: "";
  }

  @media (min-width: ${({ theme }) => theme.breakpoints[0]}) {
  }

  @media (min-width: ${({ theme }) => theme.breakpoints[1]}) {
  }

  @media (min-width: ${({ theme }) => theme.breakpoints[2]}) {
  }
`;
