import React, { useState, useLayoutEffect } from "react";

import Main from "@templates/Main";

import Cta from "@molecules/Cta";
import HalfHalf from "@molecules/HalfHalf";

import WhiteSection from "@organisms/WhiteSection";
import HeroSection from "../layout/organisms/HeroSection";
import Button from "../layout/atoms/Button";
import Input from "../layout/atoms/Input";

const IndexPage = () => {
  const [startPage, setStartPage] = useState(false);

  useLayoutEffect(() => {
    setTimeout(() => setStartPage(true), 500);
  }, [setStartPage]);

  return (
    <Main active={startPage}>
      <HeroSection
        header={
          <>
            Instant Standings
            <br />
            Awards for every GW
            <br />
            Cool stats and charts
            <small>
              For your Fantasy Premier League
              <br />
              mini league
            </small>
          </>
        }
        subheader={
          <>
            <br />
            Check it for free now!
          </>
        }
      >
        {/* <Input label="Provide your mini league ID" /> */}
        <a href={`https://app.fplrank.live/`}>
          <Button color="third" size="lg">
            Bring more fun to your mini league
          </Button>
        </a>
      </HeroSection>
      {/* <WhiteSection>
        <HalfHalf
          color="var(--brand2)"
          header="Awards for best and worst managers of every gameweek. Every >100 points score table."
          text=""
          imageRight
        />
        <HalfHalf
          color="var(--brand3)"
          header="Who had the best bench? Whom captain scored the most? Who lost most points on transfers?"
          text=""
        />
        <HalfHalf
          color="var(--brand4)"
          header="Check how league positions changed through the season."
          text=""
          imageRight
        />
        <HalfHalf
          header="Every info you need in one place live. Bonus ponits, captains, ownerships and more"
          text=""
        />
        <HalfHalf
          color="var(--brand5)"
          header="No more 'tables are being recalculated'"
          text=""
          imageRight
        />
        <HalfHalf
          color="var(--brand2)"
          header="Both on desktop and mobile"
          text=""
        />
      </WhiteSection> */}
      <Cta
        button={{
          text: "Check for free",
          link: "https://app.fplrank.live/",
        }}
      >
        <h2>
          <span>Because Mini Leagues</span>
          <span>Deserve Maximum Excitement!</span>
        </h2>
      </Cta>
    </Main>
  );
};

export default IndexPage;
