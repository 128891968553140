import React from "react";
import PropTypes from "prop-types";

import { Wrapper } from "./styles.js";

import Button from "@atoms/Button";
import HorWrap from "@atoms/HorWrap";
import Icon from "@atoms/Icon";
import Text from "@atoms/Text";

import fieldSvg from "@assets/illustrations/top-field.svg";

const Cta = ({ active, button, children }) => {
  return (
    <Wrapper active={active}>
      <Icon src={fieldSvg} />
      <Text>
        {children}
        <a href={button.link}>
          <Button color="third" size="lg">
            {button.text}
          </Button>
        </a>
      </Text>
    </Wrapper>
  );
};

Cta.propTypes = {
  active: PropTypes.bool,
  button: PropTypes.object,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

Cta.defaultProps = {
  active: true,
  button: {
    text: "Go to App",
    link: "https://app.fplrank.live/",
  },
  children: null,
};

export default Cta;
