import styled from "styled-components";

export const Wrapper = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  width: 100%;
  padding: var(--topbarHeight) 0;
  background: var(--brand1);
  color: var(--white);

  @media (min-width: ${({ theme }) => theme.breakpoints[1]}) {
    padding: var(--topbarHeight1) 0;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints[2]}) {
  }
`;

export const Top = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 48px;
  margin: 0 auto;
  padding: 64px 0;

  > div {
    position: relative;

    &:nth-child(1) {
      min-height: 300px;

      &::after {
        content: "";
        position: absolute;
        bottom: -130px;
        left: -32px;
        width: calc(100% + 64px);
        height: 200px;
        background: linear-gradient(
          180deg,
          rgba(240, 243, 253, 0) 0%,
          rgba(0, 0, 0, 1) 100%
        );
      }
    }

    > img {
      position: absolute;
      bottom: -130px;
      right: -32px;
      width: calc(100% + 64px);
    }
  }

  h1 {
    margin: 0 0 24px;
    font-size: 42px;
    font-weight: 800;

    small {
      display: block;
      margin: 24px 0 0;
      font-size: 32px;
    }
  }

  @media (min-width: ${({ theme }) => theme.breakpoints[1]}) {
    grid-template-columns: 3fr 4fr;

    > div {
      &::after {
        display: none;
      }
      > img {
        top: -152px;
        right: 0;
        bottom: auto;
        width: 50vw;
        max-width: 600px;
      }
    }

    h1 {
      margin: 0 0 24px;
      font-size: 64px;
    }
  }
`;
export const Bottom = styled.div`
  margin: 0 auto;
  width: 100%;
  max-width: 380px;
  padding: 64px 0 0;
  text-align: center;

  h2 {
    margin: 0;
    font-size: 24px;
    font-weight: 600;
    text-align: center;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints[1]}) {
    h2 {
      font-size: 32px;
    }
  }
`;
